import React from 'react'

import Blogpost from '../../components/blogpost'

const SecondPage = props => (
  <Blogpost
    disqusTitle='>Deconstructing Common Sayings I: "A Good Craftsman Never Blames His Tools"'
    disqusIdentifier="asdf"
    disqusUrl={`http://dssti.com/${props.location.pathname}`}
    metaDescription="Understanding how software development is affected by bad tools regardless of the skill of the developers involved, and how this affects enterprises"
    metaKeywords="good, bad, craftsman, tools, java, c#, software, development"
    title='>Deconstructing Common Sayings I: "A Good Craftsman Never Blames His Tools"'
  >
    <h1>
      Deconstructing Common Sayings I: "A Good Craftsman Never Blames His Tools"
    </h1>

    <p>
      There is an old saying: "A Good Craftsman Never Blames His Tools". As any
      old saying, it is often twisted and reinterpreted to mean whatever suits
      whoever uses it. But let's not jump to conclusions yet. Instead, let's
      explore different approaches:
    </p>

    <h2>Inversion: "A Bad Craftsman Blames His Tools"</h2>
    <p>
      Which can be tweaked to: "If you blame your tools, then you are a bad
      craftsman". This is often used by managers to push bad tools to engineers
      by shaming the complaining engineers.
    </p>

    <h2>
      Skill assumption: "A Good Craftsman Is So Good He Can Produce Good Work
      With Bad Tools"
    </h2>
    <p>
      This assumes a talented craftsman will be good with any toolset you give
      him. Possibly even without any toolset (he has hands, you know)! Which is
      often used to push bad tools to engineers, because if they have any pride
      they will take the challenge and produce great work!
    </p>

    <h2>
      Tool assumption: "A Good Craftsman Is Good Because He Has The Best Tools,
      Therefore He Has No Reason To Blame"
    </h2>
    <p>
      Which can be twisted to "skill is irrelevant, because there are tools to
      compensate for it". Which is often used by salesmen to push bad broken
      tools to clueless managers that try to fix a problem by throwing ludicrous
      amounts of underqualified amateurs to it. I guess these managers believe
      in the hivemind.
    </p>

    <h2>
      Skills with Tools: "A Good Craftsman Is Good Because He Searches For The
      Best Tools"
    </h2>
    <p>
      My favourite. A good craftsman doesn't blame his tools. He replaces faulty
      tools with working ones. He constructs better tools. Used by engineers to
      defend themselves against bad tools pushed by outsiders.
    </p>

    <p>
      As you can see, what you read above is strongly biased from the point of
      view of a developer.{' '}
      <em>Uuuh, salesmen are evil, and so are managers! Bad, bad people!</em>
    </p>

    <p>
      And they ignore you, and claim you are biased because you are a developer,
      and they are managers therefore they have a better point of view and you
      are wrong.
    </p>

    <p>
      What they don't tell you is that they have chosen these tools for you
      because of economical reasons (the cheapest on the market), political
      reasons (so they have more control over you) or HR reasons (so they can
      hire from a bigger pool of candidates). They never choose the tools based
      on technical reasons, because that would be thinking like a developer.
    </p>

    <p>
      What they also tell you is that they have never used and will never use
      the tools they force upon you, and that you will be the one doing the work
      with the tools they have chosen. The tools that will be grossly inadequate
      for the job (often for any kind of job). But the managers and the salesmen
      will be happy. After all, the managers provided the best tools to the
      developers, therefore are doing their work. The salesmen filled their
      quota, therefore are doing their work. On the other hand, those pesky
      developers... Why can't they just use the tools and do their job?
    </p>

    <p>
      So if you are a manager, and you choose tools for your developers for any
      reason other than technical, this is what will happen in your workplace:
    </p>

    <ul>
      <li>First, some of the engineers will complain.</li>
      <li>
        Then you will reprimand these developers, because, after all{' '}
        <em>
          people all over the world are using these tools to construct
          solutions, therefore the tools are right and you are wrong
        </em>
        .
      </li>
      <li>Then these developers will burn out.</li>
      <li>
        Then, the good ones will say "Screw this, I have other options". And
        then they will leave.
      </li>
      <li>
        On the other hand, the bad ones ones will say "Screw this, but I have no
        other options". And then they will stay.
      </li>
    </ul>

    <p>
      So, if you want to lower the average quality of your enterprise, there is
      no better way to do that than forcing bad tools upon your developers. Soon
      you will figure out who are the good ones: the ones that leave your
      enterprise.
    </p>
  </Blogpost>
)

const exports = {}
exports.frontmatter = {
  title: `Deconstructing Common Sayings I: "A Good Craftsman Never Blames His Tools"`,
  date: '2018-11-11',
  series: 'Deconstructing Common Sayings',
}

export default SecondPage
